import {useContext, createContext, useState, useEffect} from 'react';
import {useAuth} from './AuthProvider';
import {useLocation} from 'react-router-dom';
import {activeNavFromLocation} from './Utils';

const AppContext = createContext({ navActive: '',
                                   setNavActive: undefined,
                                   showPrompt: { show: false },
                                   setShowPrompt:  undefined,
                                   showGlobalPrompt: { show: false },
                                   setShowGlobalPrompt:  undefined });

export function useApp() {
    const context = useContext(AppContext);
    if (context === undefined)
    {
        throw Error('useApp must be used within AppProvider');
    }

    return context;
}

export const AppProvider = ({ children }) => {
    const { session } = useAuth();
    const location = useLocation();
    const activeNav = activeNavFromLocation(location);
    const [navActive, setNavActive] = useState(activeNav);
    const [showPrompt, setShowPrompt] = useState({ show: false });
    const [showGlobalPrompt, setShowGlobalPrompt] = useState({ show: false });

    useEffect(() => {
        if (!(navActive === 'logout' && session) && navActive !== activeNav)
        {
            setNavActive(activeNav);
        }
    });

    return (
        <AppContext.Provider value={{ navActive, setNavActive, showPrompt, setShowPrompt, showGlobalPrompt, setShowGlobalPrompt }}>
            {children}
        </AppContext.Provider>
    );
};