import React, {useEffect} from 'react';
import Icon from '@mdi/react';
import {Box, Text} from 'grommet';
import {appTheme} from '../theme';
import {useBusy} from '../libs/BusyProvider';
import {mdiAlertOutline, mdiCheckCircleOutline, mdiCloseCircleOutline, mdiLoading} from '@mdi/js';

export const busyIconAlert = {
    spin: 0,
    icon: mdiAlertOutline,
    color: appTheme.global.colors.warn
}

export const busyIconDefault = {
    spin: 1,
    icon: mdiLoading,
    color: appTheme.global.colors.brand
}

export const busyIconError = {
    spin: 0,
    icon: mdiCloseCircleOutline,
    color: appTheme.global.colors.error
}

export const busyIconSuccess = {
    spin: 0,
    icon: mdiCheckCircleOutline,
    color: appTheme.global.colors.success
}

export const BusyOutlet = ({ children }) => {
    const { busy, icon, themeMode } = useBusy();

    if (!busy && !icon)
    {
        return children;
    }
    else if (typeof busy === 'object' && busy !== null)
    {
        return busy;
    }
    else
    {
        return (
            <Box
                fill
                gap='small'
                align='center'
                justify='center'
                animation='fadeIn' >

                {(icon) && (
                        <Icon
                            size={3}
                            spin={icon.spin}
                            path={icon.icon}
                            color={icon.color[themeMode]} />
                )}
                {(typeof busy === 'string') && (
                    <Text
                        weight={300}
                        color='white'
                        textAlign='center'
                        animation='fadeIn'
                        style={{ whiteSpace: 'pre-line' }} >
                        {busy}
                    </Text>
                )}

            </Box>
        )
    }
}

export const BusyOutletDefault = () => {
    const { setBusyState } = useBusy();

    useEffect(() => {
        //console.log('BusyOutletDefault');
        setBusyState(true, busyIconDefault);

        return () => {
            setBusyState(false, false);
        };
    }, [setBusyState]);

    return <BusyOutlet />;
}
