import {useEffect, useState} from 'react';

function useThemeMode() {
    const media = window.matchMedia('(prefers-color-scheme: dark)');
    const [themeMode, setThemeMode] = useState(media.matches ? 'dark' : 'light');

    useEffect(() => {
        const listener = (e) => { setThemeMode(e.matches ? 'dark' : 'light'); };
        media.addEventListener('change', listener);

        return () => {
            media.removeEventListener('change', listener);
        };
    }, []);

    return themeMode;
}

export default useThemeMode;