
import React, {createContext, useContext, useState} from 'react';
import {busyIconError} from '../components/BusyOutlet';

const BusyContext = createContext({ busy: undefined, icon: undefined });

export const useBusy = () => {
    const context = useContext(BusyContext);
    if (context === undefined)
    {
        throw Error('useBusy must be used within BusyProvider');
    }

    return context;
}

export const BusyProvider = ({ children, themeMode }) => {
    const [busy, setBusy] = useState(false);
    const [icon, setIcon] = useState(false);

    const setBusyState = (busy, icon, timeout = undefined) => {
        setIcon(icon);
        setBusy(busy);
        // noinspection JSUnusedLocalSymbols
        return new Promise((resolve, reject) => {
            if (timeout)
            {
                setTimeout(() => {
                    resolve();
                }, timeout);
            }
            else
            {
                resolve();
            }
        });
    };

    const setBusyError = (message, debug) => {
        console.log(`${debug} Error:`, message);
        setBusyState(message, busyIconError, 2000).then(() => {
            setBusyState(false, false);
        });
    };

    return (
        <BusyContext.Provider value={{ busy, icon, setBusy, setIcon, setBusyError, setBusyState, themeMode }}>
            {children}
        </BusyContext.Provider>
    );
};
