import React from 'react';
import {useAuth} from '../libs/AuthProvider';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

export default function AuthenticatedRoute() {
    const location = useLocation();
    const { userAAL, session } = useAuth();
    //console.log(location, userAAL);
    let authenticatedMFA = true;
    if (session && userAAL)
    {
        /*
        Current Level	Next Level	Meaning
                 aal1	aal1	    User does not have MFA enrolled.
                 aal1	aal2	    User has an MFA factor enrolled but has not verified it.
                 aal2	aal2	    User has verified their MFA factor.
                 aal2	aal1	    User has disabled their MFA factor. (Stale JWT.)
         */
        //console.log(userAAL)
        if (userAAL.nextLevel === 'aal2')
        {
            if (userAAL.nextLevel !== userAAL.currentLevel)
            {
                authenticatedMFA = false;
            }
            else
            {
                const mfaVerify = JSON.parse(window.sessionStorage.getItem(`mfa-verify-${session.user.id}`));
                //console.log('mfaVerify: ', mfaVerify);
                if (!mfaVerify?.completed)
                {
                    authenticatedMFA = false;
                }
            }
        }

        if (authenticatedMFA || location.pathname.startsWith('/mfa-verify'))
        {
            return <Outlet />;
        }
        else
        {
            return <Navigate to='/mfa-verify' state={{ from: location }} />
        }
    }

    return <Navigate to='/login' state={{ from: location }} />;
}
