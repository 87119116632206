import React, {useCallback, useState} from 'react';
import Icon from '@mdi/react';
import {appTheme} from '../theme';
import {ToastNotify} from './Notify';
import {useApp} from '../libs/AppProvider';
import {assets} from '../resources/assets';
import {useAuth} from '../libs/AuthProvider';
import {activeNavFromLocation} from '../libs/Utils';
import {userSignOut} from '../libs/SupabaseProvider';
import {useNavigate, useLocation} from 'react-router-dom';
import {Avatar, Box, Button, DropButton, Header, Image, Nav, ResponsiveContext, Text, Tip} from 'grommet';
import {mdiAccountCircleOutline, mdiCogOutline, mdiDotsHorizontal, mdiEmailOutline, mdiEyeCircleOutline, mdiHomeOutline, mdiLogin, mdiLogout, mdiTextBoxOutline, mdiViewDashboard,} from '@mdi/js';

const NavButton = ({...props}) => {
    const col = (props.active) ? appTheme.global.colors.brand[props.themeMode]
                               : appTheme.global.colors.gray[props.themeMode];

    return (
        <Box fill={(props.dir === 'column') ? 'horizontal' : 'vertical'}>

            <Tip
                content={
                    <Box
                        animation='slideRight'>

                        <Text
                            weight={400}
                            size='small'
                            color={appTheme.global.colors.gray[props.themeMode]}
                            style={{
                                whiteSpace: 'nowrap',
                                textTransform: 'uppercase',
                                transition: 'font-size 0.4s ease'}} >
                            {props.label}
                        </Text>

                    </Box>
                }
                dropProps={(props.dir === 'column') ? { align: { left: 'right' }} : { align: { top: 'bottom' }}} >

                <Box
                    as='button'
                    pad='xsmall'
                    align='center'
                    key={props.key}
                    round='xxsmall'
                    justify='center'
                    direction={props.dir}
                    active={props.active}
                    border={{ size: '0' }}
                    focusIndicator={false}
                    onClick={props.onClick}
                    background='background'
                    style={{transition: 'background-color .2s linear'}}
                    hoverIndicator={(props.active) ? undefined: 'backgroundHover'}
                    margin={{ vertical: 'xsmall', horizontal: (props.dir === 'column' ? 'xxsmall' : undefined) }} >

                    <Icon
                        size={1}
                        color={col}
                        path={props.icon} />

                </Box>

            </Tip>

        </Box>
    )
}

const Toolbar = (props) => {
    const { session } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {setShowPrompt, navActive, setNavActive} = useApp();

    const onClose = () => {
        setNavActive(activeNavFromLocation(location));
        setShowPrompt({ show: false });
    };

    const confirmLogOut = () => {
        ToastNotify(mdiLogout, 'Click here to confirm sign out', '',
            onUserLogOutClick, onClose, setShowPrompt, 'normal', props.themeMode, 'CONFIRM SIGN OUT');
    };

    const onUserLogOutClick = useCallback(() => {
        // Create the session key string prior to sign out (session is null after sign out)
        const sessionKey = `mfa-verify-${session.user.id}`;
        setShowPrompt({ show: false });
        userSignOut().then(({ error }) => {
            window.sessionStorage.removeItem(sessionKey);
            navigate('/login');
            if (error)
            {
                console.log('userSignOut Error: ', error);
            }
        });
    }, [session, setShowPrompt]);

    function onNavButtonClick(item) {
        setNavActive(item.label);
        if (item.label === 'logout')
        {
            confirmLogOut();
        }
        else
        {
            navigate(`/${item.label.split('|')[0]}`);
        }
    }

    const navItems = [
        { label: '', title: 'home', icon: mdiHomeOutline },
        ...session ? [{ label: 'dash', title: 'dash', icon: mdiViewDashboard }] : [],
        { label: 'contact', title: 'contact us', icon: mdiEmailOutline },
        ...session ? [{ label: 'settings|account|setup', title: 'settings', icon: mdiCogOutline }] : [],
        ...!session ? [{ label: 'login|reset', title: 'log in', icon: mdiLogin }] : [],
        ...session ? [{ label: 'logout', title: 'log out', icon: mdiLogout }] : [],
    ];

    return (
        <Nav
            align='center'
            direction={props.dir}
            gap={(props.dir === 'row' ? 'medium' : undefined)}
            style={{ userSelect: 'none', WebkitUserSelect: 'none' }}
            pad={(props.dir === 'row' ? {horizontal: 'small'} : undefined)} >

       {navItems.map(item => (
            <NavButton
                key={item.label}
                dir={props.dir}
                icon={item.icon}
                label={item.title}
                themeMode={props.themeMode}
                onClick={() => onNavButtonClick(item)}
                active={item.label.split('|').includes(navActive)} />
       ))}

        </Nav>
    )
}

export default function Navigation(props) {
    const navigate = useNavigate();
    const sizes = ['xsmall', 'small'];
    const [dropOpen, setDropOpen] = useState(false);

    const onAccountButtonClick = () => {
        navigate('/settings/account');
    };

    const onPrivacyButtonClick = () => {
        navigate('/privacy');
    };

    const onTermsButtonClick = () => {
        navigate('/terms');
    };

    const items = [
        { key: 'terms', label: 'terms', icon: mdiTextBoxOutline, onClick: onTermsButtonClick },
        { key: 'privacy', label: 'privacy', icon: mdiEyeCircleOutline, onClick: onPrivacyButtonClick },
        { key: 'account', label: 'account', icon: mdiAccountCircleOutline, onClick: onAccountButtonClick }
    ];

    return (
        <ResponsiveContext.Consumer>
            {size => ((sizes.includes(size)) ?
                (
                    <Header
                        gap='none'
                        justify='start'
                        height={{ min: 'xxsmall' }}
                        width={{ min: 'fit-content'}}
                        animation={[
                             { type: 'fadeIn', duration: 300 },
                             { type: 'slideDown', size: 'xlarge', duration: 150 },
                        ]}
                        pad={{ horizontal: 'xsmall', vertical: 'small'}} >

                        <Box
                            pad={{ horizontal: 'small'}} >

                            <Avatar
                                size='32px'
                                src={assets.VwLogo}
                                a11yTitle='Versoworks' />

                        </Box>

                        <Toolbar
                            dir='row'
                            {...props} />

                    </Header>
                )
                :
                (
                    <Box
                        width='xxsmall'
                        fill='vertical'
                        justify='between'
                        animation={[
                            { type: 'fadeIn', duration: 300 },
                            { type: 'slideRight', size: 'xlarge', duration: 150 } ]} >

                        <Box
                            gap='xsmall'
                            overflow='auto' >

                            <Image
                                height='xxsmall'
                                src={assets.VwLogo}
                                a11yTitle='Versoworks'
                                margin={{ horizontal: 'xsmall', vertical: 'small' }} />

                            <Toolbar
                                dir='column'
                                {...props} />

                        </Box>

                        <Box
                            as='footer'
                            align='center'
                            justify='center'
                            direction='column'
                            background='background'
                            height={{ min: 'xxsmall' }} >

                            <DropButton
                                round='xxsmall'
                                open={dropOpen}
                                dropProps={{
                                    onClickOutside: () => setDropOpen(false)
                                }}
                                onClick={() => setDropOpen(!dropOpen)}
                                dropContent={<Box
                                                gap='xsmall' >
                                            {items.map((item) => (
                                                <Button
                                                    key={item.key}
                                                    justify='start'
                                                    hoverIndicator='backgroundHover'
                                                    pad={{ left: '8px', right: '12px', vertical: '8px' }}
                                                    label={<Text
                                                                size='small'
                                                                weight={400}
                                                                color='white'
                                                                style={{ textTransform: 'uppercase' }} >

                                                                {item.label}

                                                            </Text>}
                                                    onClick={() => { item.onClick(); setDropOpen(!dropOpen);}}
                                                    icon={<Icon
                                                            size={0.75}
                                                            path={item.icon}
                                                            color={appTheme.global.colors.gray[props.themeMode]} />} />
                                            ))}
                                            </Box>}
                                dropAlign={{ bottom: 'top', left: 'left' }}
                                icon={<Icon
                                        size={1}
                                        path={mdiDotsHorizontal}
                                        color={appTheme.global.colors.white[props.themeMode]} />} />

                        </Box>

                    </Box>
                )
            )}
        </ResponsiveContext.Consumer>
    )
}
