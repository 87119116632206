import {useEffect} from 'react';
import {useApp} from '../libs/AppProvider';

export const OverlayPrompt = () => {
    const { showPrompt } = useApp();

    useEffect(() => {
        let autoHideTimeout;
        const { duration, onClose } = showPrompt;
        if (duration) {
            autoHideTimeout = setTimeout(() => {
                onClose();
            }, duration * 1000);
        }

        return () => {
            if (autoHideTimeout) {
                clearTimeout(autoHideTimeout);
            }
        };
    }, [showPrompt]);

    return (showPrompt.show && showPrompt.children)
};