export const email = {
    template: {
        type: {
            expire: 'expire',
        },
        send_when: {
            before: 'before',
            on: 'on',
            after: 'after',
        }
    }
};

export const issue = {
    level: {
        critical: 1,
        warn: 2,
        info: 3,
    }
}

export function getSendDaysVerbose(template) {
    const type = (template.type === 'expire') ? 'expiry' : '';
    const days = (template.send_days === 1) ? 'day' : 'days';
    if (template.send_when === 'on')
    {
        return `${template.send_when} ${type}`;
    }
    else
    {
        return `${template.send_days} ${days} ${template.send_when} ${type}`;
    }
}

export const lazyRetry = function(componentImport, name) {
    return new Promise((resolve, reject) => {
        const sessionKey = `retry-${name}-refreshed`;
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionKey) || 'false');
        componentImport().then((component) => {
            window.sessionStorage.setItem(sessionKey, 'false');
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed)
            {
                window.sessionStorage.setItem(sessionKey, 'true');
                return window.location.reload();
            }
            reject(error);
        });
    });
};
