import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const relativeTime = require('dayjs/plugin/relativeTime');
const advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export function activeNavFromLocation(location, index = 0) {
    const pieces = new URL(location.pathname, 'https://be.verso.works').pathname.substring(1).split('/');
    return (pieces.length > index ? pieces[index] : undefined);
}

export function convertToCents(value) {
    const decimal = Math.pow(10, 2);
    return Math.round(value*decimal);
}

export function convertToDollars(value) {
    const decimal = Math.pow(10, 2);
    return (value/decimal).toFixed(2);
}

export function dateFromNow(date) {
    return dayjs(date).fromNow();
}

export function formatCurrency(lang, currency, amount) {
    const fmt = new Intl.NumberFormat(lang, {
        currency,
        style: 'currency'
    });
    return fmt.format(amount);
}

export function formatDate(date, format) {
    return dayjs(date).format(format);
}