import React, {useRef} from 'react';
import {NavBox} from './NavBox';
import AppRoutes from '../routes';
import {appTheme} from '../theme';
import preval from 'preval.macro';
import {MainGrid} from './MainGrid';
import {Box, Grommet} from 'grommet';
import * as Sentry from '@sentry/react';
import {formatDate} from '../libs/Utils';
import {GlobalPrompt} from './GlobalPrompt';
import {OverlayPrompt} from './OverlayPrompt';
import { createBrowserHistory } from 'history';
import useThemeMode from '../libs/useThemeMode';
import {AppProvider} from '../libs/AppProvider';
import Navigation from '../components/Navigation';
import {BusyProvider} from '../libs/BusyProvider';

const history = createBrowserHistory();

Sentry.init({
  autoSessionTracking: true,
  dsn: process.env.REACT_APP_SENTRYDSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/wjkpsukqgnhkackwymqw\.supabase\.co/],
    }),
    new Sentry.Replay(),
  ],
  release: 'versoworks-fe@' + formatDate(preval`module.exports = (new Date()).getTime();`, 'YYYYMMDDhhmmss'),
  // Performance Monitoring
  tracesSampleRate: 0.333, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.333, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  const mainBoxRef = useRef();
  const appThemeMode = useThemeMode();

  return (
      <Grommet
          full
          theme={appTheme}
          themeMode={appThemeMode} >

        <AppProvider>

          <MainGrid>

            <NavBox
                gridArea='nav' >

              <Navigation
                  mainRef={mainBoxRef}
                  themeMode={appThemeMode} />

            </NavBox>

            <Box
                gridArea='main'
                ref={mainBoxRef}
                direction='column' >

              <GlobalPrompt />

              <BusyProvider themeMode={appThemeMode} >

                <AppRoutes
                    mainRef={mainBoxRef}
                    themeMode={appThemeMode} />

              </BusyProvider>

            </Box>

          </MainGrid>

          <OverlayPrompt />

        </AppProvider>

      </Grommet>
  )
}
