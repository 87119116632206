import React from 'react';
import Icon from '@mdi/react';
import {appTheme} from '../theme';
import {css} from 'styled-components';
import {mdiCursorDefaultClickOutline} from '@mdi/js';
import {Notification, Text, ThemeContext} from 'grommet';

export function GlobalNotify(icon, iconCol, label, message, onClick, onClose, setShowPrompt, status, themeMode) {
    setShowPrompt({
        show: true,
        onClose: onClose,
        children: (
            <ThemeContext.Extend
                value={{
                    box: {
                        extend: css`
                            div:first-child, div:first-child > :nth-child(2) {
                              justify-content: center;
                            }
                        `,
                    }
                }}>

                <Notification
                    global
                    status={status}
                    message={message}
                    onClose={onClose}
                    actions={(label) ? [
                        {
                            gap: 'small',
                            reverse: true,
                            label: (<Text
                                        color='white'
                                        weight='normal' >

                                        {label}

                                    </Text>),
                            icon: (<Icon
                                    size={1}
                                    path={mdiCursorDefaultClickOutline}
                                    color={appTheme.global.colors.white[themeMode]} />),
                            onClick: () => onClick()
                        },
                    ] : undefined }
                    icon={<Icon
                        size={1}
                        path={icon}
                        color={appTheme.global.colors[iconCol][themeMode]} />} />

            </ThemeContext.Extend>
        ),
    });
}

export function ToastNotify(icon, label, message, onClick, onClose, setShowPrompt, status, themeMode, title) {
    setShowPrompt({
        show: true,
        duration: 3,
        onClose: onClose,
        children: (
            <Notification
                title={title}
                status={status}
                message={message}
                onClose={onClose}
                toast={{ position: 'top' }}
                actions={[
                    {
                        gap: 'small',
                        reverse: true,
                        label: (<Text
                            color='white'
                            weight='normal' >

                            {label}

                        </Text>),
                        icon: (<Icon
                            size={1}
                            path={mdiCursorDefaultClickOutline}
                            color={appTheme.global.colors.white[themeMode]} />),
                        onClick: () => onClick()
                    },
                ]}
                icon={<Icon
                    size={1}
                    path={icon}
                    color={appTheme.global.colors.brand70[themeMode]} />} />
        ),
    });
}