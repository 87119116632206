import React from 'react';
import App from './components/App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './libs/AuthProvider';
import {ClientProvider} from './libs/SupabaseProvider';
import {BrowserRouter as Router} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
          <ClientProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
          </ClientProvider>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
