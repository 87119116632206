import { createContext, useContext, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const ClientContext = createContext(undefined);
const client = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

export function createPaymentIntent(details) {
    return client.functions.invoke('payExec', {
        body: { action: 'stripeCreatePaymentIntent', details}
    });
}

export function getArticlesList(range) {
    return client.functions.invoke('userExec', {
        body: { action: 'getArticlesList', range }
    });
}

export function getMaintenanceQuote(id, period) {
    return client.functions.invoke('userExec', {
        body: { action: 'getMaintenanceQuote', id, period }
    });
}

export function getPaymentIntent(details) {
    return client.functions.invoke('payExec', {
        body: { action: 'stripeRetrievePaymentIntent', details}
    });
}

export function getProductFileURL(file, product) {
    return client.functions.invoke('userExec', {
        body: { action: 'getProductFileURL', file, product }
    });
}

export function getProductQuote(id, quantity, code) {
    return client.functions.invoke('userExec', {
        body: { action: 'getProductQuote', id, quantity, code }
    });
}

export function getQuote(id, timestamp, href) {
    return client.functions.invoke('userExec', {
        body: { action: 'getQuote', id, timestamp, href }
    });
}

export function getUserDashList() {
    return client.functions.invoke('userExec', {
        body: { action: 'getUserDashList' }
    });
}

export function sendContactEmail(email) {
    return client.functions.invoke('userExec', {
        body: { action: 'sendContactEmail', email }
    });
}

export function setSession(session) {
    return client.auth.setSession(session);
}

export function useClient() {
    const context = useContext(ClientContext);
    if (context === undefined)
    {
        throw Error('No client has been specified using Provider.');
    }

    return context;
}

export function useAuthStateChange(callback) {
    const client = useClient();

    useEffect(() => {
        const { data: { subscription } } = client.auth.onAuthStateChange(callback);

        return () => {
            subscription?.unsubscribe();
        }
    }, []);
}

export function userDeleteAccount() {
    return client.functions.invoke('userExec', {
        body: { action: 'userDeleteAccount' }
    });
}

export function userDeviceCreateWebAuthOptions() {
    return client.functions.invoke('userExec', {
        body: { action: 'userDeviceCreateWebAuthOptions' }
    });
}

export function userDeviceGetWebAuthOptions(email) {
    return client.functions.invoke('userExec', {
        body: { action: 'userDeviceGetWebAuthOptions', email }
    });
}

export function userDevicesRemove(devices) {
    return client.functions.invoke('userExec', {
        body: { action: 'userDevicesRemove', devices }
    });
}

export function userDeviceValidateWebAuthOptions(options, email) {
    return client.functions.invoke('userExec', {
        body: { action: 'userDeviceValidateWebAuthOptions', options, email }
    });
}

export function userDeviceVerifyWebAuthOptions(options, deviceName) {
    return client.functions.invoke('userExec', {
        body: { action: 'userDeviceVerifyWebAuthOptions', options, deviceName }
    });
}

export function userGetAAL() {
    return client.auth.mfa.getAuthenticatorAssuranceLevel();
}

export function userGetAuthSession() {
   return client.auth.getSession();
}

export function userMFAChallenge(factorId) {
    return client.auth.mfa.challenge({ factorId });
}

export function userMFAEnroll() {
    return client.auth.mfa.enroll({ factorType: 'totp' });
}

export function userGetMFAFactorList() {
    return client.auth.mfa.listFactors();
}

export function userMFAUnenroll(factorId) {
    return client.auth.mfa.unenroll({ factorId });
}

export function userMFAVerify(factorId, challengeId, code) {
    return client.auth.mfa.verify({ factorId, challengeId, code });
}

export function userReauthenticate() {
    return client.auth.reauthenticate();
}

export function userRefreshSession() {
    return client.auth.refreshSession();
}

export function userRegister(user) {
    return client.functions.invoke('userExec', {
        body: { action: 'userRegister', user }
    });
}

export function userResetPassword(email, redirectTo) {
    return client.auth.resetPasswordForEmail(email, { redirectTo });
}

export function userSignOut() {
    return client.auth.signOut();
}

export function userSignInWithPassword(data) {
    return client.auth.signInWithPassword({
        email: data.email,
        password: data.password,
    });
}

export function userUpdateAddress(address) {
    return client.auth.updateUser({ data: { address } });
}

export function userUpdateCurrency(currency) {
    return client.auth.updateUser({ data: { currency } });
}

export function userUpdateEmail(email) {
    return client.auth.updateUser({ email });
}

export function userUpdateName(name) {
    return client.auth.updateUser({ data: { name } });
}

export function userUpdateEmailNotice(email_notice) {
    return client.auth.updateUser({ data: { email_notice } });
}

export function userUpdatePassword(update) {
    return client.functions.invoke('userExec', {
        body: { action: 'userUpdatePassword', update }
    });
}

export function verifyCaptcha(token) {
    return client.functions.invoke('userExec', {
        body: { action: 'verifyCaptcha', token }
    });
}

export const ClientProvider = ({ children }) => {
    return (
        <ClientContext.Provider value={client}>
            {children}
        </ClientContext.Provider>
    );
}
