import {useEffect} from 'react';
import {useApp} from '../libs/AppProvider';

export const GlobalPrompt = () => {
    const { showGlobalPrompt } = useApp();

    useEffect(() => {
        let autoHideTimeout;
        const { duration, onClose } = showGlobalPrompt;
        if (duration) {
            autoHideTimeout = setTimeout(() => {
                onClose();
            }, duration * 1000);
        }

        return () => {
            if (autoHideTimeout) {
                clearTimeout(autoHideTimeout);
            }
        };
    }, [showGlobalPrompt]);

    return (showGlobalPrompt.show && showGlobalPrompt.children)
};
