import React, {lazy, Suspense} from 'react';
import {lazyRetry} from './Common';
import {Route, Routes} from 'react-router-dom';
import {BusyOutletDefault} from './components/BusyOutlet';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

const Contact = lazy(() => lazyRetry(() => import('./components/Contact'), 'Contact'));
const Dash = lazy(() => lazyRetry(() => import('./components/Dash'), 'Dash'));
const Home = lazy(() => lazyRetry(() => import('./components/Home'), 'Home'));
const Login = lazy(() => lazyRetry(() => import('./components/Login'), 'Login'));
const NotFound = lazy(() => lazyRetry(() => import('./components/NotFound'), 'NotFound'));
const MFAEnroll = lazy(() => lazyRetry(() => import('./components/MFAEnroll'), 'MFAEnroll'));
const MFAVerify = lazy(() => lazyRetry(() => import('./components/MFAVerify'), 'MFAVerify'));
const Payment = lazy(() => lazyRetry(() => import('./components/Payment'), 'Payment'));
const Privacy = lazy(() => lazyRetry(() => import('./components/Privacy'), 'Privacy'));
const Register = lazy(() => lazyRetry(() => import('./components/Register'), 'Register'));
const Reset = lazy(() => lazyRetry(() => import('./components/Reset'), 'Reset'));
const Settings = lazy(() => lazyRetry(() => import('./components/Settings'), 'Settings'));
const Terms = lazy(() => lazyRetry(() => import('./components/Terms'), 'Terms'));

export default function AppRoutes(props) {
    return (
        <Suspense fallback={<BusyOutletDefault />} >

            <Routes>

                <Route path='/' element={<Home {...props} />} />

                <Route path='/contact' element={<Contact {...props} />} />

                <Route path='/pay' element={<Payment {...props} />} />

                <Route path='/privacy' element={<Privacy {...props} />} />

                <Route path='/reset' element={<Reset {...props} />} />

                <Route path='/terms' element={<Terms {...props} />} />

                <Route element={<UnauthenticatedRoute />} >
                    <Route path='/login' element={<Login {...props} />} />
                    <Route path='/register' element={<Register {...props} />} />
                </Route>

                <Route element={<AuthenticatedRoute />} >
                    <Route path='/dash' element={<Dash {...props} />} />
                    <Route path='/mfa-enroll' element={<MFAEnroll {...props} />} />
                    <Route path='/mfa-verify' element={<MFAVerify {...props} />} />
                    <Route path='/settings/:id?/:action?' element={<Settings {...props} />} />
                </Route>

                <Route path='*' element={<NotFound {...props} />} />

            </Routes>

        </Suspense>
    );
}