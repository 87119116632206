import {grommet} from 'grommet';
import WebFont from 'webfontloader';
import {css} from 'styled-components';
import {deepMerge} from 'grommet/utils';

WebFont.load({
    google: {
        families: ['Roboto Condensed:300,400,700', 'sans-serif']
    }
});

export const appTheme = deepMerge(grommet, {
    global: {
        control: {
            border: {
                radius: '4px',
                color: 'border',
            },
            disabled: {
                opacity: 0.3,
            }
        },
        active: {
            background: 'transparent',
            color: 'white'
        },
        colors: {
            attachment: {
                dark: '#C05C35',
                light: '#E05C35'
            },
            background: {
                dark: '#1C1C1C',
                light: '#F8F9FA',
            },
            backgroundHover: {
                dark: '#ffffff0d',
                light: '#F2F3F5',
            },
            backgroundInverted: {
                dark: '#f8feff',
                light: '#1C1C1C',
            },
            backgroundLayer: {
                dark: '#232323AA',
                light: '#eeeeeeAA',
            },
            backgroundSelected: {
                dark: '#ffffff12',
                light: '#00000012',
            },
            backgroundShift: {
                dark: '#232323',
                light: '#FBFCFD',
            },
            band: {
                dark: '#ffffff0f',
                light: '#00000004',
            },
            bandInverted: {
                dark: '#0000001f',
                light: '#0000000f',
            },
            black: {
                dark: '#1C1C1C',
                light: '#f5f5f6',
            },
            border: {
                dark: '#282828',
                light: '#D8DBDF',
            },
            brand: {
                dark: '#00baff',
                light: '#10607c',
            },
            brand40: {
                dark: '#00c2ff66',
                light: '#00b7ef66',
            },
            brand70: {
                dark: '#00c2ffb3',
                light: '#00b7efb3',
            },
            brandDark: '#0092ffd9',
            buttonBG: {
                dark: '#292929',
                light: '#FBFCFD',
            },
            buttonBorder: {
                dark: '#3E3E3E',
                light: '#DFE3E5',
            },
            control: {
                dark: '#00c2ff',
                light: '#00b6ef',
            },
            defaultDark: '#1C1C1C',
            defaultLight: '#f5f5f6',
            divider: {
                dark: '#2d2d36',
                light: '#E6E8EA',
            },
            error: {
                dark: '#ee1e28',
                light: '#c62828',
            },
            error20: {
                dark: '#ee1e2822',
                light: '#c6282833',
            },
            focus: {
                dark: '#00c2ff80',
                light: '#00b6ef60',
            },
            gradFooter: {
                dark: 'linear-gradient(180deg, #232323 0%, rgba(0, 0, 0, 0.12) 100%)',
                light: 'linear-gradient(180deg, #FBFCFD 0%, rgba(0, 0, 0, 0.06) 100%)'
            },
            gradHeader: {
                dark: 'linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, #232323 100%)',
                light: 'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, #FBFCFD 100%)'
            },
            gray: {
                dark: '#707070',
                light: '#868A8B'
            },
            inputBackground: {
                dark: '#1C1C1C',
                light : '#F5F6F8'
            },
            inputError: {
                dark: '#cc1e2820',
                light : '#c6282820'
            },
            success: {
                dark: '#00C752',
                light: '#02AF06'
            },
            fail: {
                dark: '#DF0000',
                light: '#AF040C'
            },
            selectError: {
                dark: '#cc1e28A0',
                light: '#c62828A0',
            },
            warn: {
                dark: '#FFC72C',
                light: '#FEC609'
            },
            white: {
                dark: '#f5f5f6',
                light: '#1C1C1C',
            },
        },
        drop: {
            background: 'backgroundShift',
            border: {
                radius: '4px',
            },
            shadowSize: 'medium',
            extend: css`
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                overflow: hidden;
            `,
        },
        elevation: {
            dark: {
                small: '0px 1.6px 3.6px rgb(0,0,0,0.25), 0px 0px 2.9px rgb(0,0,0,0.22)',
                medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
            light: {
                small: '0px 1.6px 3.6px rgb(0,0,0,0.2), 0px 0px 2.9px rgb(0,0,0,0.15)',
                medium: '0 10px 20px rgba(0,0,0,0.15), 0 6px 6px rgba(0,0,0,0.2)',
            },
        },
        focus: {
            border: {
                color: 'focus',
            },
        },
        font: {
            family: 'Roboto Condensed',
            size: '1em',
            height: '1em',
            weight: '300',
        },
        hover: {
            background: 'backgroundHover',
            color: 'white'
        },
        input: {
            font: {
                weight: 300,
            },
        },
        selected: {
            background: 'backgroundSelected',
            color: 'white'
        },
    },
    accordion: {
        border: {
            size: '0'
        },
        heading: {
            level: 5,
            margin: 'small'
        },
        icons: {
            color: 'brand'
        }
    },
    anchor: {
        hover: {
            textDecoration: 'none',
        },
        textDecoration: 'none',
        extend: css`
            box-shadow: none;
        `,
    },
    box: {
        extend: props => css`
            outline: none;
            scrollbar-width: thin;
            &::-webkit-scrollbar {
                background: transparent;
                height: 8px;
                width: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background: ${(props.theme.dark) ? '#00c2ff' : '#00b6efAA'};
                border-radius: 4px;
            }
        `,
    },
    button: {
        border: {
            width: '1px',
            radius: '4px',
            color: 'buttonBorder',
        },
        default: {
            border: undefined,
            color: 'white',
            padding: {
                vertical: '5px',
                horizontal: '5px'
            },
            background: 'transparent',
        },
        disabled: {
            opacity: 0.5,
        },
        padding: {
            vertical: '5px',
            horizontal: '5px',
        },
        primary: {
            background: 'buttonBG',
            border: {
                width: '1px',
                radius: '4px',
                color: 'buttonBorder',
            },
            color: 'white',
            padding: {
                vertical: '5px',
                horizontal: '5px'
            }
        },
        secondary: {
            background: 'error20',
            border: undefined,
            color: {
                dark: '#f5f5f6',
                light: '#1C1C1C',
            },
            padding: {
                vertical: '5px',
                horizontal: '5px'
            }
        },
        size: {
            small: {
                border: {
                    radius: '4px',
                },
            },
            medium: {
                border: {
                    radius: '4px',
                },
            },
            large: {
                border: {
                    radius: '4px',
                },
            },
        },
    },
    calendar: {
        day: {
            extend: props => css`
                border-radius: 4px;
                background-color: ${(props.isSelected) ? ((props.theme.dark) ? '#00baff' : '#00b6ef70') : undefined};
            `,
        },
    },
    checkBox: {
        border: {
            color: 'brand',
        },
        color: 'brand',
        hover: {
            border: {
                color: 'brand40'
            }
        },
        size: '18px',
    },
    dataTable: {
        container: {
            extend: props => css`
              svg[class^='StyledIcon-sc'] {
                stroke: ${(props.theme.dark) ? '#f5f5f6' : '#1C1C1C'};
              }
            `,
        },
        header: {
            background: 'backgroundShift',
            border: {
                color: 'band',
                side: 'bottom',
            },
            color: 'white',
            extend: css`
              text-transform: uppercase;
            `,
            font: {
                weight: 700,
                size: 'small',
            },
        },
        footer: {
            background: 'backgroundShift',
            border: {
                color: 'band',
                side: 'top',
            },
        }
    },
    dateInput: {
        icon: {
            size: '20',
        },
    },
    fileInput: {
        border: {
            side: 'bottom',
            size: 'xsmall',
            style: 'solid',
        },
    },
    formField: {
        border: {
            style: 'hidden',
            side: 'bottom',
            size: '0',
        },
        checkBox: {
            pad: 'small',
        },
        content: {
            background: {
                color: 'inputBackground',
            },
            pad: 'none',
        },
        disabled: {
            background: {
                color: 'inputBackground',
            },
        },
        error: {
            background: 'inputError',
            color: 'error',
            container: {
                background: 'transparent',
            },
            margin: 'xsmall',
        },
        extend: css`
        `,
        info: {
            background: 'inputBackground',
            container: {
                background: 'transparent',
            },
            margin: 'xsmall',
        },
        help: {
            background: 'inputBackground',
            container: {
                background: 'transparent',
            },
            margin: 'xsmall',
        },
        focus: {
            border: {
                color: '#00000000',
            },
        },
        margin: {
            bottom: 'small'
        },
        round: {
            size: '4px',
        },
    },
    grommet: {
        extend: props => css`
            outline: none;
            scrollbar-width: thin;
            &::-webkit-scrollbar {
                background: transparent;
                height: 8px;
                width: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background: ${(props.theme.dark) ? '#00c2ffAA' : '#00b6efAA'};
                border-radius: 4px;
            }
        `,
    },
    layer: {
        background: 'backgroundShift',
        border: {
            intelligentRounding: true,
        },
        extend: props => css` 
            ${(props.plain === undefined) ? 'backdrop-filter: blur(10px);' : ''}            
        `,
        overlay: {
            background: 'backgroundLayer'
        },
    },
    maskedInput: {
        disabled: 'weak',
    },
    menu: {
        drop: {
            round: 'xsmall'
        },
        icons: {
            color: 'brand'
        }
    },
    notification: {
        container: {
            pad: {
                top: 'small',
            },
        },
        global: {
            container: {
                pad: {
                    vertical: 'small'
                },
            },
        },
        message: {
            margin: {
                vertical: 'small',
            },
        },
        normal: {
            global: {
                background: 'band',
            },
            toast: {
                background: {
                    color: 'backgroundShift',
                },
            },
        },
        toast: {
            time: 3000,
        },
    },
    pagination: {
        button: {
            color: 'gray',
            active: {
                color: 'brand',
            },
        }
    },
    radioButtonGroup: {
        container: {
            background: 'backgroundShift',
            round: '4px'
        },
    },
    select: {
        background: 'transparent',
        control: {
            extend: css`
                overflow: hidden;
            `,
        },
        extend: css`
            font-weight: 300;
        `,
        icons: {
            color: 'brand'
        },
        container: {
            extend: props => css`
                outline: none;
                scrollbar-width: thin;
                & > ::-webkit-scrollbar {
                    background: transparent;
                    height: 8px;
                    width: 8px;
                }
                & > ::-webkit-scrollbar-thumb {
                    background: ${(props.theme.dark) ? '#00c2ffAA' : '#00b6efAA'};
                    border-radius: 4px;
                }
            `,
        },
    },
    tab: {
        active: {
            color: 'brand70'
        },
        border: {
            active: {
                color: 'brand'
            },
            color: 'gray'
        },
        color: 'gray',
        extend: css`
                font-weight: 700;
            `,
    },
    tabs: {
        background: 'band',
        header: {
            background: 'band',
            extend: css`
                padding-top: 5px;
            `,
        },
        panel: {
            extend: props => css`
                outline: none;
                overflow: auto;
                scrollbar-width: thin;
                &::-webkit-scrollbar {
                    background: transparent;
                    height: 8px;
                    width: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background: ${(props.theme.dark) ? '#00c2ffAA' : '#00b6efAA'};
                    border-radius: 4px;
                }
            `,
        }
    },
    tip: {
        drop: {
            background: {
                color: 'backgroundShift',
            },
            margin: {
                left: 'xsmall' ,
            },
            round: 'xsmall',
            elevation: 'medium',
        },
        content: {
            elevation: 'none',
            background: 'none',
        },
    },
    textInput: {
        disabled: 'weak',
    },
});
