import vwLogo from './images/vwLogo.svg';
import amexPNG from './images/amex.png';
import visaPNG from './images/visa.png';
import vwLogoPNG from './images/vwLogo.png';
import mastercardPNG from './images/mastercard.png';
import RobotoCondensed from './fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf';
import RobotoCondensedItalic from './fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf';
import RobotoCondensedLight from './fonts/Roboto_Condensed/RobotoCondensed-Light.ttf';
import RobotoCondensedLightItalic from './fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf';
import RobotoCondensedBold from './fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf';
import RobotoCondensedBoldItalic from './fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf';

export const assets = {
    VwLogo: vwLogo,
    amexPNG: amexPNG,
    visaPNG: visaPNG,
    vwLogoPNG: vwLogoPNG,
    mastercardPNG: mastercardPNG,
    RobotoCondensed: RobotoCondensed,
    RobotoCondensedItalic: RobotoCondensedItalic,
    RobotoCondensedLight: RobotoCondensedLight,
    RobotoCondensedLightItalic: RobotoCondensedLightItalic,
    RobotoCondensedBold: RobotoCondensedBold,
    RobotoCondensedBoldItalic: RobotoCondensedBoldItalic,
};

export default assets