import React from 'react';
import {css} from 'styled-components';
import {Box, ResponsiveContext, ThemeContext} from 'grommet';

export const NavBox = ({children, ...props}) => {
    const sizes = ['xsmall', 'small'];

    return (
         <ThemeContext.Extend
             value={{
                 box: {
                     extend: props => css`
                         outline: none;
                         scrollbar-width: none;
                         &::-webkit-scrollbar {
                             height: 0;
                             width: 0;
                         }
                     `,
                 },
             }} >

             <ResponsiveContext.Consumer>
                 {size => (
                     <Box
                         {...props}
                         pad={undefined}
                         overflow='scroll'
                         border={{ color: 'border', side: sizes.includes(size) ? 'bottom' : 'right' }} >

                         {children}

                     </Box>
                 )}
             </ResponsiveContext.Consumer>

         </ThemeContext.Extend>
    )
};