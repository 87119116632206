import { createContext, useContext, useEffect, useState } from 'react';
import {useAuthStateChange, userGetAAL, userGetAuthSession} from './SupabaseProvider';

const AuthContext = createContext({ session: undefined, userAAL: undefined });

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined)
    {
        throw Error('useAuth must be used within AuthProvider');
    }

    return context;
}

export const AuthProvider = ({ children }) => {
    const [userAAL, setUserAAL] = useState(null);
    const [session, setSession] = useState(null);
    const authEvents = ['INITIAL_SESSION', 'MFA_CHALLENGE_VERIFIED', 'SIGNED_IN', 'TOKEN_REFRESHED'];

    const refreshUserAAL = async () => {
        const { data, error } = await userGetAAL();
        //console.debug('userAAL: ', data);
        setUserAAL(data);
    };

    useAuthStateChange((event, session) => {
        //console.log(`Supabase auth event: ${event}`, session);
        setSession(session);
        if (authEvents.includes(event))
        {
            refreshUserAAL();
        }
    });

    useEffect(() => {
        userGetAuthSession().then(({ data: { session }, error }) => {
            //console.debug('Session: ', session);
            setSession(session);
        });

        refreshUserAAL();
    }, [setSession, setUserAAL]);

    return (
        <AuthContext.Provider value={{ session, userAAL }}>
            {children}
        </AuthContext.Provider>
    );
}
