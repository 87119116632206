import React from 'react';
import {Grid, ResponsiveContext} from 'grommet';

export const MainGrid = ({children, ...props}) => {
    const columns = {
        xsmall: ['auto'],
        small: ['auto'],
        medium: ['auto', 'flex'],
        large: ['auto', 'flex'],
        xlarge: ['auto', 'flex'],
    };

    const rows = {
        xsmall: ['auto', 'flex'],
        small: ['auto', 'flex'],
        medium: ['auto'],
        large: ['auto'],
        xlarge: ['auto'],
    };

    const areas = {
        xsmall: [
            [ 'nav' ],
            [ 'main' ],
        ],
        small: [
            [ 'nav' ],
            [ 'main' ],
        ],
        medium: [
            [ 'nav', 'main' ],
        ],
        large: [
            [ 'nav', 'main' ],
        ],
        xlarge: [
            [ 'nav', 'main' ],
        ],
    };

    return (
        <ResponsiveContext.Consumer>
            {size => {

                let rowsVal = (rows && rows[size]) ? rows[size] : rows;
                let columnsVal = (columns && columns[size]) ? columns[size] : columns;
                let areasVal = (areas && !Array.isArray(areas)) ? areas[size] : areas;

                return (
                    <Grid
                        fill
                        {...props}
                        areas={!areasVal ? undefined : areasVal}
                        rows={!rowsVal ? size : rowsVal}
                        columns={!columnsVal ? size : columnsVal} >
                        {children}
                    </Grid>
                );
            }}
        </ResponsiveContext.Consumer>
    )
};